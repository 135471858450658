<template>
    <div aria-labelledby="filter-heading">
        <div class="hidden sm:block sm:relative">
            <div v-auto-animate>
                <div class="relative">
                    <h2 id="filter-heading" class="sr-only">
                        {{ t('location') }}
                    </h2>
                    <div
                        class="mx-auto flex space-x-6 divide-x divide-gray-200 text-sm"
                    >
                        <div class="flex-auto">
                            <button
                                class="sm:w-44 group flex flex-1 items-center text-gray-500"
                                @click="
                                    showLocationDropdown = !showLocationDropdown
                                "
                            >
                                <MapPinIcon
                                    class="mr-2 h-6 w-6 flex-none text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                <span class="text-left text-xs w-full">
                                    <span
                                        v-if="showMultipleLocationDropdown"
                                        class="w-full inline-flex"
                                    >
                                        <span class="flex-none"
                                            >{{ t('your_store') }}&nbsp;{{
                                                t('for')
                                            }}</span
                                        >
                                        <span
                                            v-if="locationStore.getZipcode"
                                            class="ml-1 grow"
                                        >
                                            {{ locationStore.getZipcode }}</span
                                        >
                                        <span
                                            v-else
                                            class="animate-pulse rounded-full h-4 w-full bg-gray-200 ml-1"
                                        />
                                    </span>
                                    <span v-else>
                                        <span>{{ t('your_store') }}</span>
                                    </span>
                                    <span
                                        class="flex font-medium items-center text-gray-600 group-hover:text-gray-700"
                                    >
                                        <span
                                            v-if="
                                                locationStore.getCurrentLocationCityState
                                            "
                                            >{{
                                                locationStore.getCurrentLocationCityState
                                            }}</span
                                        >
                                        <span
                                            v-else
                                            class="dm-placeholder animate-pulse rounded-full h-4 w-full bg-gray-200"
                                        />
                                        <component
                                            :is="
                                                showLocationDropdown
                                                    ? ChevronUpIcon
                                                    : ChevronDownIcon
                                            "
                                            class="ml-1 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="showLocationDropdown" class="">
                    <div
                        v-if="currentLocation"
                        class="mx-auto mt-6 flex flex-col space-y-6 lg:space-y-0 lg:flex-row gap-x-4 p-4 text-base sm:p-6 md:gap-x-6 lg:p-8 bg-gray-200 rounded-lg"
                    >
                        <div class="lg:w-1/2">
                            <div
                                class="text-xl sm:text-2xl font-bold tracking-tight text-gray-900"
                            >
                                {{ currentLocation.name }}
                            </div>
                            <div
                                class="mt-4 flex flex-col space-y-4 text-gray-500"
                            >
                                <!--                                <div> -->
                                <!--                                    <DealerMeNuxtLink -->
                                <!--                                        class="text-sm" -->
                                <!--                                        :href=" -->
                                <!--                                            locationStore.getCurrentLocationSlug -->
                                <!--                                        " -->
                                <!--                                    > -->
                                <!--                                        View more store details -->
                                <!--                                    </DealerMeNuxtLink> -->
                                <!--                                </div> -->
                                <div v-if="currentLocation.address">
                                    <div class="font-bold">
                                        {{ t('address') }}:
                                    </div>
                                    <div class="mt-1">
                                        {{
                                            currentLocation.address
                                                .street_address
                                        }}<br />{{
                                            currentLocation.address.city
                                        }},
                                        {{ currentLocation.address.state }}
                                        {{ currentLocation.address.zip }}<br />
                                        <DealerMeNuxtLink
                                            class="text-sm"
                                            :href="
                                                locationStore.getCurrentLocationSlug
                                            "
                                        >
                                            {{ t('directions') }}
                                        </DealerMeNuxtLink>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        locationStore.getCurrentLocationSalesNumber ||
                                        locationStore.getCurrentLocationServiceNumber ||
                                        locationStore.getCurrentLocationPartsNumber
                                    "
                                >
                                    <div class="font-bold">
                                        {{ t('phone_number') }}:
                                    </div>
                                    <div class="mt-1 flex flex-col space-y-0.5">
                                        <div
                                            v-if="
                                                locationStore.getCurrentLocationSalesNumber
                                            "
                                        >
                                            {{ t('sales') }}:
                                            {{
                                                convertPhoneToHuman(
                                                    locationStore.getCurrentLocationSalesNumber,
                                                )
                                            }}
                                        </div>
                                        <div
                                            v-if="
                                                locationStore.getCurrentLocationServiceNumber
                                            "
                                        >
                                            {{ t('service') }}:
                                            {{
                                                convertPhoneToHuman(
                                                    locationStore.getCurrentLocationServiceNumber,
                                                )
                                            }}
                                        </div>
                                        <div
                                            v-if="
                                                locationStore.getCurrentLocationPartsNumber
                                            "
                                        >
                                            {{ t('parts') }}:
                                            {{
                                                convertPhoneToHuman(
                                                    locationStore.getCurrentLocationPartsNumber,
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!--                                <div v-if="locationStore.getLocationCount > 1"> -->
                                <!--                                    <button class="btn"> -->
                                <!--                                        {{ t('switch_locations') }} -->
                                <!--                                    </button> -->
                                <!--                                </div> -->
                            </div>
                        </div>

                        <!--                        <div -->
                        <!--                            class="w-full lg:w-1/2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8" -->
                        <!--                        > -->
                        <!--                            <div -->
                        <!--                                class="group h-96 aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2" -->
                        <!--                            > -->
                        <!--                                <img -->
                        <!--                                    alt="" -->
                        <!--                                    class="object-cover object-center group-hover:opacity-75" -->
                        <!--                                /> -->
                        <!--                                <div -->
                        <!--                                    aria-hidden="true" -->
                        <!--                                    class="bg-gradient-to-b from-transparent to-black opacity-50" -->
                        <!--                                /> -->
                        <!--                                <div class="flex items-end p-6"> -->
                        <!--                                    <div> -->
                        <!--                                        <h3 class="font-semibold text-white"> -->
                        <!--                                            <a href="#"> -->
                        <!--                                                <span -->
                        <!--                                                    class="absolute inset-0" -->
                        <!--                                                /> -->
                        <!--                                                {{ -->
                        <!--                                                    locationStore.getCurrentLocationCityState -->
                        <!--                                                }} -->
                        <!--                                            </a> -->
                        <!--                                        </h3> -->
                        <!--                                        <p -->
                        <!--                                            aria-hidden="true" -->
                        <!--                                            class="mt-1 text-sm text-white" -->
                        <!--                                        > -->
                        <!--                                            {{ t('get_directions') }} -->
                        <!--                                        </p> -->
                        <!--                                    </div> -->
                        <!--                                </div> -->
                        <!--                            </div> -->
                        <!--                            <div -->
                        <!--                                class="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full" -->
                        <!--                            > -->
                        <!--                                <img -->
                        <!--                                    alt="" -->
                        <!--                                    class="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full" -->
                        <!--                                /> -->
                        <!--                                <div -->
                        <!--                                    aria-hidden="true" -->
                        <!--                                    class="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" -->
                        <!--                                /> -->
                        <!--                                <div -->
                        <!--                                    class="flex items-end p-6 sm:absolute sm:inset-0" -->
                        <!--                                > -->
                        <!--                                    <div> -->
                        <!--                                        <h3 class="font-semibold text-white"> -->
                        <!--                                            <a href="#"> -->
                        <!--                                                <span -->
                        <!--                                                    class="absolute inset-0" -->
                        <!--                                                /> -->
                        <!--                                                Trucks {{ t('in') }} -->
                        <!--                                                {{ -->
                        <!--                                                    locationStore.getCurrentLocationCityState -->
                        <!--                                                }} -->
                        <!--                                            </a> -->
                        <!--                                        </h3> -->
                        <!--                                        <p -->
                        <!--                                            aria-hidden="true" -->
                        <!--                                            class="mt-1 text-sm text-white" -->
                        <!--                                        > -->
                        <!--                                            {{ t('shop_now') }} -->
                        <!--                                        </p> -->
                        <!--                                    </div> -->
                        <!--                                </div> -->
                        <!--                            </div> -->
                        <!--                            <div -->
                        <!--                                class="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full" -->
                        <!--                            > -->
                        <!--                                <img -->
                        <!--                                    alt="" -->
                        <!--                                    class="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full" -->
                        <!--                                /> -->
                        <!--                                <div -->
                        <!--                                    aria-hidden="true" -->
                        <!--                                    class="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0" -->
                        <!--                                /> -->
                        <!--                                <div -->
                        <!--                                    class="flex items-end p-6 sm:absolute sm:inset-0" -->
                        <!--                                > -->
                        <!--                                    <div> -->
                        <!--                                        <h3 class="font-semibold text-white"> -->
                        <!--                                            <a href="#"> -->
                        <!--                                                <span -->
                        <!--                                                    class="absolute inset-0" -->
                        <!--                                                /> -->
                        <!--                                                Cars {{ t('in') }} -->
                        <!--                                                {{ -->
                        <!--                                                    locationStore.getCurrentLocationCityState -->
                        <!--                                                }} -->
                        <!--                                            </a> -->
                        <!--                                        </h3> -->
                        <!--                                        <p -->
                        <!--                                            aria-hidden="true" -->
                        <!--                                            class="mt-1 text-sm text-white" -->
                        <!--                                        > -->
                        <!--                                            {{ t('shop_now') }} -->
                        <!--                                        </p> -->
                        <!--                                    </div> -->
                        <!--                                </div> -->
                        <!--                            </div> -->
                        <!--                        </div> -->
                    </div>
                </div>
            </div>

            <div class="absolute top-0 right-0">
                <div class="mx-auto flex justify-end">
                    <Menu as="div" class="relative inline-block">
                        <div class="flex">
                            <MenuButton
                                class="group inline-flex justify-center text-sm font-medium text-gray-700"
                            >
                                <Bars3BottomLeftIcon
                                    class="mr-2 h-5 w-5 flex-shrink-0 hover:text-gray-900"
                                    aria-hidden="true"
                                />
                                <span class="hover:text-gray-900">
                                    {{ t('sort_by') }} {{ currentSort.name }}
                                </span>

                                <!--                                <ChevronDownIcon -->
                                <!--                                    class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" -->
                                <!--                                    aria-hidden="true" -->
                                <!--                                /> -->
                            </MenuButton>
                        </div>

                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems
                                class="absolute right-0 z-100 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <div class="py-1">
                                    <MenuItem
                                        v-for="(option, index) in sortOptions"
                                        :key="index"
                                    >
                                        <button
                                            :class="[
                                                currentSortIndex === index
                                                    ? 'font-bold'
                                                    : 'hover:font-medium',
                                                'block w-full px-4 py-2 text-left text-sm ',
                                            ]"
                                            @click="updateSort(index)"
                                        >
                                            {{
                                                t(
                                                    option.translate_key,
                                                    option.name,
                                                )
                                            }}
                                        </button>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>
        <div class="lg:hidden relative -mt-6">
            <!--            <div class="flex gap-3 items-center justify-end px-4">-->
            <div class="flex gap-3 items-center px-4 pt-4">
                <!--                <div class="w-full relative mt-2 rounded-md shadow-sm">-->
                <!--                    <div-->
                <!--                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"-->
                <!--                    >-->
                <!--                        <MagnifyingGlassIcon-->
                <!--                            class="h-5 w-5 text-gray-400"-->
                <!--                            aria-hidden="true"-->
                <!--                        />-->
                <!--                    </div>-->
                <!--                    <input-->
                <!--                        id="text"-->
                <!--                        type="text"-->
                <!--                        name="search"-->
                <!--                        class="block w-full rounded-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
                <!--                        placeholder="Search inventory"-->
                <!--                    />-->
                <!--                </div>-->

                <div>
                    <button
                        class="flex items-center"
                        @click="inventoryFilterStore.toggleMobileFilter"
                    >
                        <AdjustmentsHorizontalIcon
                            class="h-4 w-4 flex-none mr-2"
                            aria-hidden="true"
                        />
                        <span>{{ t('filters') }}</span>
                    </button>
                </div>
            </div>
            <div
                v-if="pills.length > 0"
                class="flex py-2 px-4 space-x-2 overflow-x-auto mb-1"
            >
                <Pills
                    :pills="pills"
                    :is-mobile="true"
                    @remove-filter="emit('removeFilter', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
    // ArrowsRightLeftIcon,
    AdjustmentsHorizontalIcon,
    Bars3BottomLeftIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    MapPinIcon,
} from '@heroicons/vue/20/solid'
import type { PropType } from 'vue'
import Pills from '~/components/SearchResultsPage/SearchResultsFilter/Pills.vue'

const locationStore = useLocationStore()
const inventoryStore = useInventoryStore()
const domainStore = useDomainStore()
const inventoryFilterStore = useInventoryFilterStore()

const props = defineProps({
    query: {
        type: Object as PropType<Record<string, string | number>>,
        default: () => {},
    },
    pills: {
        type: Array as PropType<Pill[]>,
        default: () => [],
    },
})

const emit = defineEmits(['removeFilter'])

const { t } = useI18n()

const showLocationDropdown = ref(false)

const currentLocation = computed(() => locationStore.getCurrentLocation)

const showMultipleLocationDropdown = computed(
    () =>
        locationStore.getLocationCount > 1 &&
        domainStore.geoSettings.defaultLocationId === null,
)

const currentSortIndex = computed(() => {
    if (!props.query.sort) {
        return 0
    }

    const findIndex = sortOptions.value.findIndex(
        (item) =>
            item.sort === inventoryStore.sort?.sort &&
            item.sort_direction === inventoryStore.sort?.sortDirection,
    )

    return findIndex > -1 ? findIndex : 0
})

const sortOptions = computed(() => inventoryStore.getFilteredSortOptions)
const currentSort = computed(() => inventoryStore.getCurrentSortOption)

function updateSort(index: number) {
    const option = sortOptions.value[index]

    inventoryStore.updateSort(
        option.sort === null
            ? null
            : {
                  sort: option.sort,
                  sortDirection: option.sort_direction ?? null,
              },
    )
}
</script>
